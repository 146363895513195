import React, {FC, useEffect} from "react";
import {Cell, Col, Grid, Icon, iconsTypes, Row, Text, TooltipWrapper} from "lavaa";
import css from "../RafScore.module.scss";
import {useClients} from "../../../../../Hooks/UseClients";
import {emptyStatData, prepareData, PreparedStatDataType} from "./util";
import {useStatic} from "../../../../../Hooks/UseStatic";

interface IProps {
	title: string;
	scoreKey: string;
}

const tooltipText = "*Total Raw Score = 67% of 2020 CMS-HCC Model Adjusted Raf Score (V24) + 33% of 2024 CMS-HCC Model Adjusted Raf Score (V28)";

// Raf Score Statistics Group
const RafScoreStatGroup: FC<IProps> = React.memo((props) => {
	const {title, scoreKey} = props;
	const { clientScores } = useClients();
	const { ICD10Codes } = useStatic();

	const [active, setActive] = React.useState<boolean>(false);
	const [preparedData, setPreparedData] = React.useState<PreparedStatDataType>(emptyStatData);
	const scoresData = clientScores[scoreKey];

	useEffect(() => {
		const prepareDataLocal = prepareData(scoresData, ICD10Codes);
		setPreparedData(prepareDataLocal);
	}, [clientScores, scoreKey, ICD10Codes]);

	if(!clientScores || !clientScores.hasOwnProperty(scoreKey)) return null;

	return (
		<Col className={css.StatItem}>
			<Row className={css.StatHeader} onClick={() => setActive(prevState => !prevState)}>
				<TooltipWrapper tooltipText={tooltipText} positionV="top" positionH="left">
					<Row className={css.StatTitleBox}>
						<Text size="x3" bold={true}>{title}</Text>
						<Text className={css.StatTitleScore} size="x3" bold={true}>{(scoresData && scoresData.score ? scoresData.score.toFixed(2) : 0)}</Text>
					</Row>
				</TooltipWrapper>
				<Row className={css.StatHeaderArrow} data-active={active}>
					<Icon name={iconsTypes.arrowDown}/>
				</Row>
			</Row>
			{
				active && (
					<Grid className={css.StatGrid}>
						
						{/* Grid Header */}
						<Cell className={css.StatCell}>
							<Text size="x2" bold={true}>Diagnoses</Text>
						</Cell>
						<Cell className={css.StatCell}>
							<Text size="x2" bold={true}>V24</Text>
						</Cell>
						<Cell className={css.StatCell}>
							<Text size="x2" bold={true}>V28</Text>
						</Cell>
						<Cell className={css.StatCell}>
							{/*	<Text size="x2" bold={true}>Blended</Text>*/}
						</Cell>
						
						{/* No Data Message */}
						{
							preparedData.data.length === 0 && (
								<Cell className={css.StatCellEmpty}>
									<Text size="x3" bold={true} className={css.StatCellEmptyText}>No Diagnoses Codes</Text>
								</Cell>
							)
						}

						{/* Codes */}
						{
							preparedData.data.map((data, index) => {
								return (
									<React.Fragment key={index}>
										<Cell className={css.StatCell}>
											<Text size="x2" ellipsis={true} maxLines={1} className={css.StatCellName}>{data.name}</Text>
										</Cell>

										<Cell className={css.StatCell}>
											<Text size="x2">
												{
													data.v24Code ? data.code : "-"
												}
											</Text>
										</Cell>

										<Cell className={css.StatCell}>
											<Text size="x2">
												{
													data.v28Code ? data.code : "-"
												}
											</Text>
										</Cell>

										<Cell className={css.StatCell}>
											{/*	<Text size="x2"></Text>*/}
										</Cell>
									</React.Fragment>
								)
							})
						}

						<Cell className={css.StatCell}>
							<Text size="x2" bold={true}>Raf Score Adjusted</Text>
						</Cell>

						<Cell className={css.StatCell}>
							<Text size="x2" bold={true}>
								{preparedData.scores.v24.toFixed(2)}
							</Text>
						</Cell>

						<Cell className={css.StatCell}>
							<Text size="x2" bold={true}>
								{preparedData.scores.v28.toFixed(2)}
							</Text>
						</Cell>

						<Cell className={css.StatCell}>
							{/*<Text size="x2" bold={true}>*/}
							{/*	{preparedData.scores.blended.toFixed(2)}*/}
							{/*</Text>*/}
						</Cell>
					</Grid>
				)
			}
		</Col>
	)
});

export {RafScoreStatGroup};
